/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"

const query = graphql`
  query BuyWithConfidenceQuery {
    confidenceBanner: file(name: { eq: "confidence-banner" }) {
      props: childMarkdownRemark {
        body: html
        bits: frontmatter {
          title
          subtitle
          closingParagraph
          picture {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`

const circleUrl = '/assets/site/logo-TSBWC-circle.png'
const logoUrl = '/assets/site/logo-TSBWC.png'

const getImage = (image, alt) => image && image.childImageSharp && image.childImageSharp.gatsbyImageData
  ? <GatsbyImage alt={alt} image={image.childImageSharp.gatsbyImageData} />
  : ''

export const BuyWithConfidence = () => {
  const {
    confidenceBanner: {
      props: { body, bits: { title, picture, subtitle, closingParagraph } }
    }
  } = useStaticQuery(query)

  return <>
    <section className="confidence">
      <div className="confidence__image">
        {getImage(picture, 'Buy')}
      </div>
      <div className="confidence__content">
        <h2>{title}</h2>
        <div dangerouslySetInnerHTML={{ __html: body }}/>
      </div>
    </section>
    <section className="confidence confidence__second">
      <div className="confidence__content how__certified">
        <h3>{subtitle}</h3>
        {closingParagraph}
        <div>
          <img src={circleUrl} alt="confidence" />
          <img src={logoUrl} alt="more confidence" />
        </div>
      </div>
    </section>
  </>
}
