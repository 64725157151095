/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"
import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { SalesMessage } from "../components/sales-message"
import { Feature } from "../components/feature"
import { WhatsIncluded } from "../components/whats-included"
import { BuyWithConfidence } from "../components/buy-with-confidence"
import { Faq } from "../components/faq"
import { QuoteProcess, initialModalPropsState } from "../components/quote-process"
import { dataStorageKeys, getLocalStorageItem } from "../util/local-storage-wrapper"
import { ConfidenceBanner } from "../components/confidence-banner"
import { DriveCarefree } from "../components/drive-carefree"
import { ProductsSection } from "../components/products-section"

export const pageQuery = graphql`
  query HomeQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      props: frontmatter {
        title
      }
    }
  }
`

const HomePage = ({ data }) => {
  const featureRef = React.useRef(null)
  const { markdownRemark: { props: { title }, html } } = data
  const [modalProps, setModalProps] = React.useState(initialModalPropsState)
  const [quoteData, setQuoteData] = React.useState(null)
  const [vehicleData, setVehicleData] = React.useState(null)

  React.useEffect(() => {
    if (!vehicleData) {
      setVehicleData(getLocalStorageItem(dataStorageKeys.vehicleData))
    }
    if (!quoteData) {
      setQuoteData(getLocalStorageItem(dataStorageKeys.quoteData))
    }
  }, [vehicleData, quoteData])

  const kickOffQuote = (data) => {
    setModalProps({
      defaultReg: data.reg,
      defaultMileage: data.mileage,
      isActive: true,
      defaultStep: 1
    })
  }

  const initiateQuote = () => {
    setModalProps({ ...initialModalPropsState, isActive: true })
  }

  const changeQuote = (data) => {
    setModalProps({
      defaultData: data,
      defaultReg: data.reg,
      defaultMileage: data.mileage,
      isActive: true,
      defaultStep: 1
    })
  }

  const closeModal = () => {
    setModalProps(initialModalPropsState)
    setQuoteData(getLocalStorageItem(dataStorageKeys.quoteData))
    setVehicleData(getLocalStorageItem(dataStorageKeys.vehicleData))
    if (featureRef.current) {
      featureRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }

  return (
    <Layout className="primary-container">
      <div className="hero-background" />
      <div className="cloud x1"></div>
      <div className="cloud x2"></div>
      <div className="cloud x3"></div>
      <div className="cloud x4"></div>
      <div className="cloud x5"></div>
      <div className="cloud x6"></div>
      <div className="cloud x7"></div>
      <Seo />
      {modalProps.isActive ? <QuoteProcess {...modalProps} closeModal={closeModal}/> : null}
      <div className="home-container">
        <div className="home-content">
          <h1>{title}</h1>
          <div
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </div>
        <SalesMessage
          action={kickOffQuote}
        />
      </div>
      <ConfidenceBanner/>
      <Feature featureRef={featureRef} vehicleData={vehicleData} quoteData={quoteData} setAction={changeQuote} initiateQuote={initiateQuote}/>
      <DriveCarefree/>
      <WhatsIncluded/>
      <ProductsSection quoteData={quoteData} initiateQuote={initiateQuote} />
      <BuyWithConfidence/>
      <Faq thisPage="home"/>
    </Layout>
  )
}

export default HomePage
