/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

const CarefreeItem = ({ title, imageData }) => <div className="carefree-item">
  <div className="carefree-item-box">
    <GatsbyImage alt={title} image={imageData} />
  </div>
  <h3>{title}</h3>
</div>

const query = graphql`
  query {
    driveCarefree: file(name: { eq: "drive-carefree" }) {
      props: childMarkdownRemark {
        bits: frontmatter {
          sectionTitle
          title1
          picture1 {
            img: childImageSharp {
              imgData: gatsbyImageData
            }
          }
          title2
          picture2 {
            img: childImageSharp {
              imgData: gatsbyImageData
            }
          }
          title3
          picture3 {
            img: childImageSharp {
              imgData: gatsbyImageData
            }
          }
          title4
          picture4 {
            img: childImageSharp {
              imgData: gatsbyImageData
            }
          }
        }
      }
    }
  }
`

export const DriveCarefree = () => {
  const {
    driveCarefree: {
      props: {
        bits: {
          sectionTitle,
          title1,
          picture1: {
            img: {
              imgData: imgData1
            }
          },
          title2,
          picture2: {
            img: {
              imgData: imgData2
            }
          },
          title3,
          picture3: {
            img: {
              imgData: imgData3
            }
          },
          title4,
          picture4: {
            img: {
              imgData: imgData4
            }
          }
        }
      }
    }
  } = useStaticQuery(query)

  return <section className="drive-carefree-section">
    <div className="drive-carefree">
      <h2>{sectionTitle}</h2>
      <div className="carefree-items">
        <div className="carefree-item-pair">
          <CarefreeItem title={title1} imageData={imgData1} />
          <CarefreeItem title={title2} imageData={imgData2} />
        </div>
        <div className="carefree-item-pair">
          <CarefreeItem title={title3} imageData={imgData3} />
          <CarefreeItem title={title4} imageData={imgData4} />
        </div>
      </div>
    </div>
  </section>
}
